





































































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import IngredientEntity, {IngredientUnitEntity, ValidatorRules} from "@/entities/ingredient-entity";
import AddDeleteTable from "@/components/Table/AddDeleteTable.vue";

import {Units} from '@/entities/unit-entities';
import { help as PopoverText } from '@/lang/help/ingredient-create';
import cloneDeep from "lodash/cloneDeep";

@Component({
  components: {
    AddDeleteTable
  }
})
export default class extends Vue {
  private readonly PopoverText = PopoverText;
  private readonly rules = ValidatorRules;
  private readonly Units = Units;

  @Prop({required: true}) private model!:IngredientEntity;
  @Prop({required: true}) private enabled!:boolean;

  private get c_enabled() { return this.enabled; };
  private set c_enabled(val) { this.$emit('update:enabled', val); };

  private original: IngredientUnitEntity[] = [];
  private created() {
    this.c_enabled = this.model.ingredientUnits && this.model.ingredientUnits.length > 0;
    this.original = cloneDeep(this.model.ingredientUnits);
  }

  private row(scope: { row: IngredientUnitEntity }) {
    return scope.row;
  }

  private async onEnabledChange(newVal: boolean) {
    if (!newVal && this.original.length > 0) {
      const res = await this.onDeleting('設定を解除');
      if (!res) {
        this.c_enabled = true;
      }
    } else if (newVal && this.model.ingredientUnits.length === 0) {
      this.addRow();
    }
  }

  private async addRow() {
    this.model.ingredientUnits.push(new IngredientUnitEntity());
  }
  private async onDeleting(label = '削除') {
    return await this.$confirm2(
      this.$t('商品や中間原材料の内訳でこの単位が使用されている場合、その使用量はグラムに変換されます。'),
      this.$t('確認'),
      {
        confirmButtonText: label,
        cancelButtonText: this.$t('キャンセル'),
        confirmButtonClass: 'c-button c-button--danger',
        cancelButtonClass: 'c-button c-button--secondary',
      }
    );
  }
}
